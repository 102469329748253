import api from '../index'

const faceMotionApi = {
  cancelFaceMotion: async (id: number): Promise<boolean> => {
    const { data } = await api.post(`/v1/admin/face-motion/${id}/cancel`)
    return data
  },
}

export default faceMotionApi
