import React from 'react'

import { Button, Checkbox, Form, Input, Space } from 'antd'
import { Cookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

import { userApi } from '@/api'
import { type SignInRequestBody } from '@/api/types/request.type'

const SignIn: React.FC = () => {
  const navigate = useNavigate()
  const onFinish = (values: any) => {
    const userInfo: SignInRequestBody = {
      email: values.email,
      password: values.password,
    }

    void userApi.signIn(userInfo).then(res => {
      const cookies = new Cookies()
      if (res.authToken) {
        cookies.set('media-admin-token', res.authToken, {
          path: '/',
          maxAge: 60 * 60 * 24 * 7,
        })
        navigate('/voice-study')
      }
    })
  }

  const navigateToSignUp = () => {
    navigate('/sign-up')
  }
  return (
    <div className="h-screen flex items-center justify-center">
      <Form
        name="signIn"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        style={{ maxWidth: 400, width: '100%' }}
        onFinish={onFinish}
      >
        <Form.Item
          label="이메일"
          name="email"
          rules={[{ required: true, message: 'email 입력하세용' }]}
        >
          <Input autoComplete="username" autoFocus />
        </Form.Item>

        <Form.Item
          label="비밀번호"
          name="password"
          rules={[{ required: true, message: '비밀번호 입력하세용' }]}
        >
          <Input.Password autoComplete="current-password" />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Checkbox>이메일 기억</Checkbox>
        </Form.Item>

        <Form.Item className="flex justify-end">
          <Space>
            <Button
              htmlType="button"
              onClick={navigateToSignUp}
              className="mr-5"
              type="link"
            >
              회원가입
            </Button>
            <Button type="primary" htmlType="submit">
              로그인
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SignIn
