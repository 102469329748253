import axios, { type AxiosInstance } from 'axios'
import { Cookies } from 'react-cookie'

import userApi from './user'
import voiceApi from './voice'

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: status => status >= 200 && status < 300,
})

const cookies = new Cookies()

api.interceptors.request.use(
  config => {
    const authToken = cookies.get('media-admin-token') as string
    if (!!authToken && config.headers) {
      config.headers.Authorization = `Bearer ${authToken}`
    }
    config.timeout = 60 * 1000
    return config
  },
  async error => await Promise.reject(error),
)

export default api

export { voiceApi, userApi }
