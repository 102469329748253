import api from '../index'

const processingApi = {
  cancelMergeVideos: async (id: number): Promise<boolean> => {
    const { data } = await api.post(`/v1/admin/video-convert/async/merge-videos/${id}/cancel`)
    return data
  },

}

export default processingApi
