import React, { useMemo } from 'react'

import { Button, Layout, Select, theme } from 'antd'
import { Cookies } from 'react-cookie'
import { Outlet, useNavigate } from 'react-router-dom'

import LayoutSider from './LayoutSider'

const { Header, Content } = Layout

enum Domain {
  DEV = 'dev',
  LOCAL = 'http://localhost:3000',
  PROD = 'prod',
}

const ProtectLayout: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const navigate = useNavigate()

  const getCurrentDomain = useMemo(() => {
    const { href } = window.location
    switch (true) {
      case href.includes(Domain.LOCAL):
        return Domain.LOCAL
      case href.includes(Domain.DEV):
        return Domain.DEV
      case href.includes(Domain.PROD):
        return Domain.PROD
      default:
        return Domain.DEV
    }
  }, [])

  const handleChange = (e: any) => {
    switch (e) {
      case Domain.LOCAL:
        window.location.href = Domain.LOCAL
        break
      case Domain.DEV:
        window.open('https://dev.admin.media-management.klleon.io')
        break
      case Domain.PROD:
        window.open('https://admin.media-management.klleon.io')
        break
      default:
        break
    }
  }

  const handleClickLogout = () => {
    const cookies = new Cookies()
    cookies.remove('media-admin-token', { path: '/' })
    navigate('/sign-in')
  }

  return (
    <Layout className="h-screen">
      <LayoutSider />
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            borderBottom: '1px solid #e9e9e9',
          }}
        >
          <div className="h-full flex justify-end items-center px-10 gap-4">
            <Select
              value={getCurrentDomain}
              onChange={handleChange}
              options={[
                // { value: 'local', label: 'local' },
                { value: Domain.DEV, label: '개발' },
                { value: Domain.PROD, label: '운영' },
              ]}
            />
            <Button type="primary" onClick={handleClickLogout}>
              로그아웃
            </Button>
          </div>
        </Header>
        <Content className="my-0 mx-4">
          <div
            className="p-6 h-full"
            style={{
              background: colorBgContainer,
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default ProtectLayout
