import api from '../index'

const imageApi = {
  cancelImageFaceSwap: async (id: number): Promise<boolean> => {
    const { data } = await api.post(`/v1/admin/face-swap/image/${id}/cancel`)
    return data
  },
  cancelImageFaceDetect: async (id: number): Promise<boolean> => {
    const { data } = await api.post(`/v1/admin/face-detect/image/${id}/cancel`)
    return data
  },
}

export default imageApi
