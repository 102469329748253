import api from '../index'

const voiceApi = {
  cancelTts: async (id: number): Promise<any> => {
    const { data } = await api.post(
      `/v1/admin/voice-study/text-to-voice/${id}/cancel`,
    )
    return data
  },
  cancelStudy: async (id: number): Promise<any> => {
    const { data } = await api.post(`/v1/admin/voice-study/${id}/cancel`)
    return data
  },
}

export default voiceApi
