export enum CustomTableTitleType {
  Input = 'input',
  // Select = 'select',
  MultiSelect = 'multi-select',
  // Range = 'range',
}

export type ColumnApiResponse = Record<string, string | ColumnApiSelectType>

export interface ColumnApiSelectType {
  name: string
  query: string | null
  value: string[] | null
}

export enum HideButtonStatus {
  'FAIL',
  'COMPLETE',
  'DONE',
  'CANCELED',
}
