import api from '../index'

const videoApi = {
  cancelVideoFaceSwap: async (id: number): Promise<boolean> => {
    const { data } = await api.post(`/v1/admin/face-swap-dubbing/${id}/cancel`)
    return data
  },
  cancelVideoFaceDetect: async (id: number): Promise<boolean> => {
    const { data } = await api.post(`/v1/admin/face-detect/video/${id}/cancel`)
    return data
  },
}

export default videoApi
