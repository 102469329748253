import { useMemo, useState } from 'react'

import {
  PlayCircleFilled,
  TeamOutlined,
  RetweetOutlined,
  SoundFilled,
  UserOutlined,
  MehOutlined,
  FileImageOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import KlleonLogo from '@assets/images/klleon-logo.png'
import { Menu, type MenuProps } from 'antd'
import Sider from 'antd/es/layout/Sider'
import {
  type MenuItemGroupType,
  type MenuItemType,
  type SubMenuType,
} from 'antd/es/menu/hooks/useItems'
import { useLocation, useNavigate } from 'react-router-dom'

type MenuItem = MenuItemType | SubMenuType | MenuItemGroupType

export const routeItems: MenuItem[] = [
  {
    key: 'virtual-face',
    icon: <UserOutlined />,
    label: '가상얼굴',
  },
  {
    key: 'loop-dubbing',
    icon: <RetweetOutlined />,
    label: '루프 더빙',
  },
  {
    key: 'voice',
    icon: <SoundFilled />,
    label: '목소리',
    children: [
      {
        key: 'voice/voice-study',
        label: '목소리 학습',
        title: 'test',
      },
      {
        key: 'voice/tts',
        label: '음성 생성',
      },
    ],
  },
  {
    key: 'video',
    icon: <PlayCircleFilled />,
    label: '비디오',
    children: [
      {
        key: 'video/face-swap',
        label: '비디오 페이스 스왑 & 더빙',
      },
      {
        key: 'video/face-detect',
        label: '비디오로부터 얼굴 인식',
      },
    ],
  },
  {
    key: 'image',
    icon: <FileImageOutlined />,
    label: '이미지',
    children: [
      {
        key: 'image/face-swap',
        label: '이미지 페이스 스왑',
      },
      {
        key: 'image/face-detect',
        label: '이미지로부터 얼굴 인식',
      },
    ],
  },
  {
    key: 'face-motion',
    icon: <MehOutlined />,
    label: '얼굴/모션',
  },
  {
    key: 'processing',
    icon: <ReloadOutlined spin />,
    label: '프로세싱',
    children: [
      {
        key: 'processing/circle-video',
        label: 'Circle 비디오 생성',
      },
      {
        key: 'processing/composite-object-video-background',
        label: '객체화 비디오/배경이미지 합성',
      },
      {
        key: 'processing/composite-object-video-color',
        label: '객체화 비디오/배경색 합성',
      },
      {
        key: 'processing/composite-video-background',
        label: '비디오/배경이미지 합성',
      },
      {
        key: 'processing/composite-video-color',
        label: '비디오/배경색 합성',
      },
      {
        key: 'processing/merge-videos',
        label: '비디오 이어붙이기',
      },
    ],
  },
  {
    key: 'admin-user',
    icon: <TeamOutlined />,
    label: '어드민 계정',
  },
]

const LayoutSider: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false)

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const getCurrentKey = useMemo(() => pathname.substring(1), [pathname])

  const getOpenKeys = useMemo(() => {
    const path = pathname.split('/').filter(str => str.length > 0)
    const key = path[path.length - 2]
    return key
  }, [pathname])

  const onClick: MenuProps['onClick'] = ({ keyPath }) => {
    navigate(`/${keyPath[0]}`)
  }

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={setCollapsed}
      width={250}
    >
      <img src={KlleonLogo} className="w-auto h-16" />
      <Menu
        defaultSelectedKeys={[getCurrentKey]}
        defaultOpenKeys={[getOpenKeys]}
        onClick={onClick}
        mode="inline"
        items={routeItems}
      />
    </Sider>
  )
}

export default LayoutSider
