import { cloneElement, useEffect, useState, type ReactElement } from 'react'
import React from 'react'

import { Modal } from 'antd'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'

import { type ActionColumnOption } from './CustomTable'

import api from '@/api'

interface TableMapperProps {
  actionColumnOptions?: ActionColumnOption[]
  children: ReactElement
  colOption?: Record<string, Record<string, any>>
  dataApiUrl: string
  titleBarApiUrl: string
}

const TableMapper: React.FC<TableMapperProps> = props => {
  const {
    children,
    dataApiUrl,
    titleBarApiUrl,
    actionColumnOptions,
    colOption,
  } = props
  const location = useLocation()

  const [dataSource, setDataSource] = useState([])
  const [columns, setColumn] = useState({})
  const [pageLength, setPageLength] = useState(1)
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [isColumnLoading, setIsColumnLoading] = useState(true)

  const getDataSource = async () => {
    try {
      const { data } = await api.get(`${dataApiUrl}`, {
        params: { ...qs.parse(location.search), pageSize: 10 },
      })
      setDataSource(data.data)
      setPageLength(data.pageTotalSize)
    } catch (e: any) {
      Modal.error({
        title: '데이터를 불러오는데 실패했습니다.',
        content: e.response.data.message,
      })
    } finally {
      setIsDataLoading(false)
    }
  }

  const getColumns = async () => {
    const { data } = await api.get(`${titleBarApiUrl}`)
    setColumn({ ...data, ...colOption })
    setIsColumnLoading(false)
  }

  const refetch = () => {
    getDataSource().catch(console.error)
  }

  useEffect(() => {
    getDataSource().catch(console.error)
  }, [location.search])

  useEffect(() => {
    getColumns().catch(console.error)
  }, [])

  return cloneElement(children, {
    data: dataSource,
    columns,
    pageLength,
    isLoading: isColumnLoading || isDataLoading,
    actionColumnOptions: actionColumnOptions?.map(obj => {
      return {
        ...obj,
        onClick: (record: any) => {
          Modal.confirm({
            title: obj.title,
            content: obj.modalMessage?.(record) ?? (
              <div className="text-lg">
                taskId: {record.taskId} {obj.title}
              </div>
            ),
            onOk: async () => {
              return await obj.onOk?.(record).then(() => {
                refetch()
              })
            },
          })
        },
      }
    }),
  })
}

export default TableMapper
