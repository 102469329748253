import api from '../index'

const dubbingLoopApi = {
  cancelDubbingLoop: async (id: number): Promise<boolean> => {
    const { data } = await api.post(`/v1/admin/dubbing-loop/${id}/cancel`)
    return data
  },
}

export default dubbingLoopApi
