import { useMemo } from 'react'

import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import { Tag } from 'antd'

interface StatusTagProps {
  closable?: boolean
  onClose?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  status: string
}
const StatusTag: React.FC<StatusTagProps> = props => {
  const { status, closable, onClose } = props

  return useMemo(() => {
    switch (status) {
      case 'COMPLETE':
      case 'DONE':
        return (
          <Tag
            icon={<CheckCircleOutlined />}
            color="#87d068"
            className="inline-flex"
            closable={closable}
            onClose={onClose}
          >
            {status}
          </Tag>
        )
      case 'FAIL':
        return (
          <Tag
            icon={<CloseCircleOutlined />}
            color="#f50"
            className="inline-flex"
            closable={closable}
            onClose={onClose}
          >
            {status}
          </Tag>
        )
      case 'CANCELED':
        return (
          <Tag
            icon={<MinusCircleOutlined />}
            className="inline-flex"
            closable={closable}
            onClose={onClose}
          >
            {status}
          </Tag>
        )

      case 'DISPATCHED':
        return (
          <Tag
            icon={<SyncOutlined spin />}
            color="processing"
            className="inline-flex"
            closable={closable}
            onClose={onClose}
          >
            {status}
          </Tag>
        )

      case 'READY':
        return (
          <Tag
            icon={<ClockCircleOutlined />}
            color="warning"
            className="inline-flex"
            closable={closable}
            onClose={onClose}
          >
            {status}
          </Tag>
        )

      default:
        return (
          <Tag closable={closable} onClose={onClose}>
            {status}
          </Tag>
        )
    }
  }, [status])
}

export default StatusTag
