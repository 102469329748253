import { type DefaultPageProps } from '../types/page.type'

import { userApi } from '@/api'
import { CustomTable } from '@/components/table'
import { type ActionColumnOption } from '@/components/table/CustomTable'
import TableMapper from '@/components/table/TableMapper'

const actionColumnOptions: ActionColumnOption[] = [
  {
    title: '허용',
    label: '허용',
    hide: (record: any) => record.active,
    onOk: async (record: any) => await userApi.allowAccess(record.id),
    buttonProps: {},
  },
  {
    title: '탈퇴',
    label: '탈퇴',
    modalMessage: record => `이름: ${record.name as string}  탈퇴처리합니다.`,
    onOk: async (record: any) => await userApi.withdrawal(record.id),
    buttonProps: {
      type: 'primary',
      danger: true,
    },
  },
]

const AdminUser: React.FC<DefaultPageProps> = props => {
  return (
    <TableMapper
      dataApiUrl={props.dataApiUrl}
      titleBarApiUrl={props.titleBarApiUrl}
      actionColumnOptions={actionColumnOptions}
      colOption={{
        active: {
          name: '활성화 여부',
          query: 'active',
          value: [true, false],
        },
      }}
    >
      <CustomTable uniqueKey="taskId" />
    </TableMapper>
  )
}

export default AdminUser
