import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Input, Select } from 'antd'
import { type ColumnType } from 'antd/es/table'
import qs from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'

import { CustomTableTitleType } from './table.type'

export type AlignType = 'left' | 'right' | 'center'

export type CustomTableColumnType = ColumnType<any> & {
  align?: AlignType
  options?: Array<{ label: string; value: string }>
  queryKey?: string
  title: string
  type?: CustomTableTitleType
}
export interface CustomTableTitleProps {
  column: CustomTableColumnType
}

const CustomTableTitle: React.FC<CustomTableTitleProps> = props => {
  const { column } = props

  const location = useLocation()
  const navigate = useNavigate()
  const [value, setValue] = useState<string | string[]>()

  useEffect(() => {
    const query = qs.parse(location.search)
    const queryValue = query[column.queryKey as string]

    if (column.type === CustomTableTitleType.MultiSelect) {
      if (!queryValue) {
        setValue([])
        return
      }
      setValue((queryValue as string).split(','))
    }

    if (column.type === CustomTableTitleType.Input) {
      if (!queryValue) {
        setValue('')
        return
      }
      setValue(queryValue as string)
    }
  }, [location.search, column.queryKey])

  const handleChange = useCallback(
    (value: string | string[]) => {
      const oldQuery = qs.parse(location.search)

      const newQuery = qs.stringify(
        {
          ...oldQuery,
          [column.queryKey as string]: value,
          pageIndex: 1,
        },
        {
          arrayFormat: 'comma',
        },
      )

      navigate(
        {
          pathname: location.pathname,
          search: newQuery,
        },
        {
          replace: true,
        },
      )
    },
    [location.search, location.pathname, navigate, column.queryKey],
  )

  const getAdditionalComponent = useMemo(() => {
    // if (column.key === 'status') {
    //   return (
    //     <Select
    //       mode="multiple"
    //       style={{ minWidth: 150 }}
    //       allowClear
    //       value={value}
    //       options={column.options}
    //       placeholder={column.title}
    //       // tagRender={props => <Tag {...props}>{props.label}</Tag>}
    //       onChange={handleChange}
    //     >
    //       {column.options?.map(option => {
    //         return (
    //           <Select.Option value={option.value} key={option.value}>
    //             <StatusTag status={option.value} />
    //           </Select.Option>
    //         )
    //       })}
    //     </Select>
    //   )
    // }
    switch (column.type) {
      case CustomTableTitleType.MultiSelect:
        return (
          <Select
            mode="multiple"
            style={{ minWidth: 150 }}
            allowClear
            value={value}
            placeholder={column.title}
            options={column.options}
            onChange={handleChange}
          />
        )

      case CustomTableTitleType.Input:
        return (
          <Input.Search
            value={value}
            placeholder={column.title}
            allowClear
            onChange={e => {
              setValue(e.target.value)
            }}
            onSearch={handleChange}
          />
        )
      default:
        return <span className="text-center">{column.title}</span>
    }
  }, [value])

  return <div className="flex flex-col gap-2">{getAdditionalComponent}</div>
}

export default CustomTableTitle
