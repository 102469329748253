import { type DefaultPageProps } from '../types/page.type'

import { voiceApi } from '@/api'
import { CustomTable } from '@/components/table'
import { type ActionColumnOption } from '@/components/table/CustomTable'
import { HideButtonStatus } from '@/components/table/table.type'
import TableMapper from '@/components/table/TableMapper'

const actionColumnOptions: ActionColumnOption[] = [
  {
    title: '취소',
    label: '생성 취소',
    hide: record => Object.values(HideButtonStatus).includes(record.status),
    onOk: async (record: any) => await voiceApi.cancelTts(record.taskId),
    buttonProps: {
      type: 'primary',
      danger: true,
    },
  },
]

const Tts: React.FC<DefaultPageProps> = props => {
  return (
    <TableMapper
      dataApiUrl={props.dataApiUrl}
      titleBarApiUrl={props.titleBarApiUrl}
      actionColumnOptions={actionColumnOptions}
    >
      <CustomTable uniqueKey="taskId" />
    </TableMapper>
  )
}

export default Tts
