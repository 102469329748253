import React from 'react'

import { ConfigProvider } from 'antd'
import ReactDOM from 'react-dom/client'

import 'antd/dist/reset.css'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#ff8400',
      },
      components: {
        Button: {
          colorLink: '#ff8400',
          colorLinkHover: '#ffcc7a',
        },
      },
    }}
  >
    <App />
  </ConfigProvider>,
)

reportWebVitals()
