import { Cookies } from 'react-cookie'
import { Navigate, Outlet, Route } from 'react-router-dom'

import ProtectLayout from '@/layouts/ProtectLayout'
import DubbingLoop from '@/pages/dubbingLoop/DubbingLoop'
import FaceMotion from '@/pages/faceMotion/FaceMotion'
import ImageFaceDetect from '@/pages/image/ImageFaceDetect'
import ImageFaceSwap from '@/pages/image/ImageFaceSwap'
import CircleVideo from '@/pages/processing/CircleVideo'
import CompositeObjectVideoBackground from '@/pages/processing/CompositeObjectVideoBackground'
import CompositeObjectVideoColor from '@/pages/processing/CompositeObjectVideoColor'
import CompositeVideoBackground from '@/pages/processing/CompositeVideoBackground'
import CompositeVideoColor from '@/pages/processing/CompositeVideoColor'
import MergeVideos from '@/pages/processing/MergeVideos'
import { type DefaultPageProps } from '@/pages/types/page.type'
import { AdminUser } from '@/pages/user'
import { VideoFaceDetect, VideoFaceSwap } from '@/pages/video'
import { VirtualFace } from '@/pages/virtualFace'
import { Tts, VoiceStudy } from '@/pages/voice'

export const AuthGuard = () => {
  const cookies = new Cookies()
  const mediaAdminToken = cookies.get('media-admin-token')
  if (!mediaAdminToken) {
    return <Navigate to="/sign-in" replace />
  }
  return <Outlet />
}

const routeDefaultProps: Record<string, DefaultPageProps> = {
  // Media Management
  voiceStudy: {
    dataApiUrl: '/v1/admin/voice-study/list',
    titleBarApiUrl: '/v1/admin/voice-study/title-bar',
  },
  tts: {
    dataApiUrl: '/v1/admin/voice-study/text-to-voice/list',
    titleBarApiUrl: '/v1/admin/voice-study/text-to-voice/title-bar',
  },
  imageFaceDetect: {
    dataApiUrl: '/v1/admin/face-detect/image/list',
    titleBarApiUrl: '/v1/admin/face-detect/image/title-bar',
  },
  imageFaceSwap: {
    dataApiUrl: '/v1/admin/face-swap/image/list',
    titleBarApiUrl: '/v1/admin/face-swap/image/title-bar',
  },
  videoFaceDetect: {
    dataApiUrl: '/v1/admin/face-detect/video/list',
    titleBarApiUrl: '/v1/admin/face-detect/video/title-bar',
  },
  videoFaceSwap: {
    dataApiUrl: '/v1/admin/face-swap-dubbing/list',
    titleBarApiUrl: '/v1/admin/face-swap-dubbing/title-bar',
  },
  loopDubbing: {
    dataApiUrl: '/v1/admin/dubbing-loop/list',
    titleBarApiUrl: '/v1/admin/dubbing-loop/title-bar',
  },
  virtualFace: {
    dataApiUrl: '/v1/admin/virtual-face/list',
    titleBarApiUrl: '/v1/admin/virtual-face/title-bar',
  },
  faceMotion: {
    dataApiUrl: '/v1/admin/face-motion/list',
    titleBarApiUrl: '/v1/admin/face-motion/title-bar',
  },
  // Media Processing
  circleVideo: {
    dataApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/circle-video/list`,
    titleBarApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/circle-video/title-bar`,
  },
  compositeObjectVideoBackground: {
    dataApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/composite-object-video-background/list`,
    titleBarApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/composite-object-video-background/title-bar`,
  },
  compositeObjectVideoColor: {
    dataApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/composite-object-video-color/list`,
    titleBarApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/composite-object-video-color/title-bar`,
  },
  compositeVideoBackground: {
    dataApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/composite-video-background/list`,
    titleBarApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/composite-video-background/title-bar`,
  },
  compositeVideoColor: {
    dataApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/composite-video-color/list`,
    titleBarApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/composite-video-color/title-bar`,
  },
  mergeVideos: {
    dataApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/merge-videos/list`,
    titleBarApiUrl: `${process.env.REACT_APP_PROCESSING_API_URL as string
      }/v1/admin/video-convert/async/merge-videos/title-bar`,
  },

  adminUser: {
    dataApiUrl: '/v1/admin/user/list',
    titleBarApiUrl: '/v1/admin/user/title-bar',
  },
}

const ProtectRouter = (
  <Route element={<AuthGuard />}>
    <Route element={<ProtectLayout />}>
      <Route path="voice">
        <Route
          path="voice-study"
          element={<VoiceStudy {...routeDefaultProps.voiceStudy} />}
        />
        <Route path="tts" element={<Tts {...routeDefaultProps.tts} />} />
      </Route>
      <Route path="image">
        <Route
          path="face-detect"
          element={<ImageFaceDetect {...routeDefaultProps.imageFaceDetect} />}
        />
        <Route
          path="face-swap"
          element={<ImageFaceSwap {...routeDefaultProps.imageFaceSwap} />}
        />
      </Route>
      <Route path="video">
        <Route
          path="face-detect"
          element={<VideoFaceDetect {...routeDefaultProps.videoFaceDetect} />}
        />
        <Route
          path="face-swap"
          element={<VideoFaceSwap {...routeDefaultProps.videoFaceSwap} />}
        />
      </Route>
      <Route
        path="virtual-face"
        element={<VirtualFace {...routeDefaultProps.virtualFace} />}
      />
      <Route
        path="loop-dubbing"
        element={<DubbingLoop {...routeDefaultProps.loopDubbing} />}
      />
      <Route
        path="face-motion"
        element={<FaceMotion {...routeDefaultProps.faceMotion} />}
      />
      <Route path="processing">
        <Route
          path="circle-video"
          element={<CircleVideo {...routeDefaultProps.circleVideo} />}
        />
        <Route
          path="composite-object-video-background"
          element={
            <CompositeObjectVideoBackground
              {...routeDefaultProps.compositeObjectVideoBackground}
            />
          }
        />
        <Route
          path="composite-object-video-color"
          element={
            <CompositeObjectVideoColor
              {...routeDefaultProps.compositeVideoBackground}
            />
          }
        />
        <Route
          path="composite-video-background"
          element={
            <CompositeVideoBackground
              {...routeDefaultProps.compositeVideoBackground}
            />
          }
        />
        <Route
          path="composite-video-color"
          element={
            <CompositeVideoColor {...routeDefaultProps.compositeVideoColor} />
          }
        />

        <Route
          path="merge-videos"
          element={
            <MergeVideos {...routeDefaultProps.mergeVideos} />
          }
        />
      </Route>
      <Route
        path="admin-user"
        element={<AdminUser {...routeDefaultProps.adminUser} />}
      />
    </Route>
  </Route>
)

export default ProtectRouter
