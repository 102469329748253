import { type DefaultPageProps } from '../types/page.type'

import virtualFaceApi from '@/api/virtualFace'
import { CustomTable } from '@/components/table'
import { type ActionColumnOption } from '@/components/table/CustomTable'
import TableMapper from '@/components/table/TableMapper'

const actionColumnOptions: ActionColumnOption[] = [
  {
    title: '취소',
    label: '실행',
    onOk: async (record: any) =>
      await virtualFaceApi.cancelVirtualFace(record.taskId),
    buttonProps: {
      type: 'primary',
      danger: true,
    },
  },
]

const VirtualFace: React.FC<DefaultPageProps> = props => {
  return (
    <TableMapper
      dataApiUrl={props.dataApiUrl}
      titleBarApiUrl={props.titleBarApiUrl}
      actionColumnOptions={actionColumnOptions}
    >
      <CustomTable uniqueKey="taskId" />
    </TableMapper>
  )
}

export default VirtualFace
