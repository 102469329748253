import React from 'react'

import { RouterProvider } from 'react-router-dom'

import router from '@/routers'

import './App.scss'

const App: React.FC = () => {
  return <RouterProvider router={router}></RouterProvider>
}

export default App
