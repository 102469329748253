import { Modal } from 'antd'

import api from '../index'
import {
  type SignInRequestBody,
  type SignUpRequestBody,
} from '../types/request.type'
import {
  type SignUpResponse,
  type SignInResponse,
} from '../types/response.type'

const userApi = {
  // Get user info
  getUserInfo: () => {
    return {
      name: 'John Doe',
      age: 30,
      email: '',
    }
  },
  signIn: async (userInfo: SignInRequestBody): Promise<SignInResponse> => {
    const { data } = await api.post('/v1/admin/user/login', userInfo)
    if (data.success) {
      return data
    } else {
      Modal.error({
        title: '로그인 실패',
        content: data.message,
      })
      throw new Error(data.message)
    }
  },
  signUp: async (userInfo: SignUpRequestBody): Promise<SignUpResponse> => {
    const { data } = await api.post('/v1/admin/user/signup', userInfo)
    if (data.success) {
      return data
    } else {
      Modal.error({
        title: '회원가입 실패',
        content: data.message,
      })
      throw new Error(data.message)
    }
  },
  allowAccess: async (id: number): Promise<boolean> => {
    const { data } = await api.post('/v1/admin/user/allow', {
      id,
    })
    if (data.success) {
      return data
    } else {
      Modal.error({
        title: '허용 실패',
        content: data.message,
      })
      throw new Error(data.message)
    }
  },
  withdrawal: async (id: number): Promise<boolean> => {
    const { data } = await api.post('/v1/admin/user/withdrawal', {
      id,
    })
    if (data.success) {
      return data
    } else {
      Modal.error({
        title: '탈퇴 실패',
        content: data.message,
      })
      throw new Error(data.message)
    }
  },
}

export default userApi
