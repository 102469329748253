import { Navigate, Route } from 'react-router-dom'

import SignIn from '@/pages/sign/SignIn'
import SignUp from '@/pages/sign/SignUp'

const PublicRouter = (
  <Route>
    <Route path="sign-in" element={<SignIn />} />
    <Route path="sign-up" element={<SignUp />} />
    <Route path="*" element={<Navigate to="voice/voice-study" replace />} />
  </Route>
)

export default PublicRouter
