import React, { useCallback, useMemo } from 'react'

import StatusTag from '@components/tag/StatusTag'
import { Button, type ButtonProps, Table, message } from 'antd'
import qs from 'query-string'
import { useNavigate } from 'react-router-dom'

import CustomTableTitle, {
  type AlignType,
  type CustomTableColumnType,
} from './CustomTableTitle'
import {
  type ColumnApiSelectType,
  type ColumnApiResponse,
  CustomTableTitleType,
} from './table.type'

export type FixedType = 'left' | 'right' | boolean

export interface ActionColumnOption {
  buttonProps?: ButtonProps
  hide?: (e: any) => boolean
  label: string
  modalMessage?: (e: any) => string
  onClick?: (e: any) => void
  onOk?: (e: any) => Promise<unknown>
  title: string
}

export interface CustomTableProps {
  actionColumnOptions?: ActionColumnOption[]
  columns?: ColumnApiResponse
  data?: any[]
  isLoading?: boolean
  pageLength?: number
  uniqueKey: string
}

const getRowClassName = (record: any, index: number) => {
  const hasFailRecord = Object.values(record).includes('FAIL')

  switch (true) {
    case hasFailRecord:
      return 'table-row-error'
    case index % 2 === 0:
      return 'table-row-light'
    default:
      return 'table-row-dark'
  }
}


const CustomTable: React.FC<CustomTableProps> = props => {
  const {
    data = [],
    columns = {},
    uniqueKey,
    actionColumnOptions = [],
    pageLength = 1,
    isLoading,
  } = props

  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()

  const getTableWidth = useMemo(() => {
    return Object.keys(columns).length * 250
  }, [columns])

  

  const handleChangePage = useCallback(
    (value: number) => {
      const oldQuery = qs.parse(location.search)
      const newQuery = qs.stringify(
        {
          ...oldQuery,
          pageIndex: value,
        },
        {
          arrayFormat: 'comma',
        },
      )

      navigate(
        {
          pathname: location.pathname,
          search: newQuery,
        },
        {
          replace: true,
        },
      )
    },
    [location.search, navigate],
  )

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).catch(err => {
      console.error('Failed to copy: ', err)
    })
    void messageApi.open({
      type: 'success',
      content: 'copied text to clipboard',
    })
  }

  const normalizedCol = (cols: ColumnApiResponse, key: string) => {
    const selectCol = cols[key] as ColumnApiSelectType
    return {
      title: selectCol.name,
      dataIndex: key,
      align: 'center' as AlignType,
      key,
      fixed: key === uniqueKey ? 'left' : (false as FixedType),
      width: key === uniqueKey ? 200 : key === 'status' ? 200 : undefined,
      queryKey: selectCol.query ?? undefined,
      render: (value: any) => {
        if (key === 'status') return <StatusTag status={value} />
        else {
          return (
            <div
              className="cursor-pointer"
              onClick={() => {
                handleCopy(value)
              }}
            >
              {contextHolder}
              {String(value ?? '-')}
            </div>
          )
        }
      },
    }
  }

  const getColumns = (cols: ColumnApiResponse): CustomTableColumnType[] => {
    return Object.keys(cols).map(key => {
      const defaultCol = normalizedCol(cols, key)

      const hasQueryKey = (colOption: ColumnApiSelectType) => !!colOption.query
      const hasArrayValue = (colOption: ColumnApiSelectType) =>
        Array.isArray(colOption.value)

      switch (true) {
        case !hasQueryKey(cols[key] as ColumnApiSelectType): 
          return defaultCol
        case hasArrayValue(cols[key] as ColumnApiSelectType):
          return {
            ...defaultCol,
            type: CustomTableTitleType.MultiSelect,
            options: (cols[key] as ColumnApiSelectType).value?.map(
              (value: string) => ({
                value,
                label: `${value}`,
              }),
            ),
          }
        case hasQueryKey(cols[key] as ColumnApiSelectType):
          return {
            ...defaultCol,
            type: CustomTableTitleType.Input,
          }

        default:
          return defaultCol
      }
    })
  }

  const newColumns = getColumns(columns).map(column => ({
    ...column,
    title: <CustomTableTitle column={column} />,
  }))

  const actionColumns: CustomTableColumnType[] = actionColumnOptions.map(
    option => ({
      ...option,
      key: option.title,
      fixed: 'right' as FixedType,
      align: 'center',
      width: 120,
      render: (_, record) => {
        if (option.hide?.(record)) return null
        return (
          <Button
            disabled={option.hide?.(record)}
            onClick={() => option.onClick?.(record)}
            {...option.buttonProps}
          >
            {option.label}
          </Button>
        )
      },
    }),
  )

  return (
    <Table
      bordered
      loading={isLoading}
      columns={[...newColumns, ...actionColumns]}
      dataSource={data}
      rowKey={uniqueKey}
      scroll={{ x: getTableWidth }}
      rowClassName={getRowClassName}
      pagination={{
        position: ['bottomCenter'],
        total: pageLength * 10,
        showSizeChanger: false,
        onChange: handleChangePage,
        current: Number((qs.parse(location.search).pageIndex ?? 1) as string),
      }}
    />
  )
}

export default CustomTable
