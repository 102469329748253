import React from 'react'

import { Button, Form, Input, Modal, Space } from 'antd'
import { useNavigate } from 'react-router-dom'

import { type SignUpRequestBody } from '@/api/types/request.type'
import userApi from '@/api/user'

const onFinish = (values: any) => {
  const userInfo: SignUpRequestBody = {
    email: values.email,
    password: values.password,
    name: values.username,
  }

  void userApi.signUp(userInfo).then(res => {
    if (res.success) {
      Modal.success({
        title: '회원가입 성공',
        content: '로그인 페이지로 이동합니다.',
        onOk: () => {
          window.location.href = '/sign-in'
        },
      })
    }
  })
}

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo)
}

const SignUp: React.FC = () => {
  const navigate = useNavigate()

  const navigateToSignIn = () => {
    navigate('/sign-in')
  }
  return (
    <div className="h-screen flex items-center justify-center">
      <Form
        name="signUp"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        style={{ maxWidth: 400, width: '100%' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="이메일"
          name="email"
          rules={[{ required: true, message: '이메일 입력하세용' }]}
        >
          <Input autoFocus />
        </Form.Item>
        <Form.Item
          label="이름"
          name="username"
          rules={[{ required: true, message: '이름 입력하세용' }]}
        >
          <Input autoComplete="username" />
        </Form.Item>
        <Form.Item
          label="비밀번호"
          name="password"
          rules={[{ required: true, message: '비밀번호 입력하세용' }]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          label="비밀번호 확인"
          name="passwordConfirm"
          rules={[
            { required: true, message: '비밀번호 확인 입력하세용' },
            ({ getFieldValue }) => ({
              validator: async (rule, value) => {
                if (!value || getFieldValue('password') === value) {
                  await Promise.resolve()
                  return
                }
                return await Promise.reject(
                  new Error('비밀번호가 일치하지 않습니다.'),
                )
              },
            }),
          ]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>

        <Form.Item className="flex justify-end">
          <Space>
            <Button
              htmlType="button"
              type="link"
              onClick={navigateToSignIn}
              className="mr-5"
            >
              로그인
            </Button>
            <Button type="primary" htmlType="submit">
              회원가입
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SignUp
