import api from '../index'

const virtualFaceApi = {
  cancelVirtualFace: async (id: number): Promise<boolean> => {
    const { data } = await api.post(`/v1/admin/virtual-face/${id}/cancel`)
    return data
  },
}

export default virtualFaceApi
